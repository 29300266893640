<template>
  <b-container fluid>
    <b-row>
      <b-col lg="12" sm="12">
        <iq-card>
            <template v-slot:body>
                <b-overlay :show="loading">
                    <b-row>
                    <b-col lg="12" sm="12">
                        <ValidationObserver ref="form"  v-slot="{ handleSubmit, reset }">
                        <b-form  @submit.prevent="handleSubmit(createData)" @reset.prevent="reset" enctype="multipart/form-data">
                            <ValidationProvider name="Room Type (En)" vid="room_type_en" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="room_type_en"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('elearning_config.room_type_en')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="room_type_en"
                                v-model="roomTypeData.room_type_en"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Room Type (Bn)" vid="room_type_bn" rules="required">
                            <b-form-group
                                class="row"
                                label-cols-sm="4"
                                label-for="room_type_bn"
                                slot-scope="{ valid, errors }"
                            >
                                <template v-slot:label>
                                {{$t('elearning_config.room_type_bn')}} <span class="text-danger">*</span>
                                </template>
                                <b-form-input
                                id="room_type_bn"
                                v-model="roomTypeData.room_type_bn"
                                :state="errors[0] ? false : (valid ? true : null)"
                                ></b-form-input>
                                <div class="invalid-feedback">
                                {{ errors[0] }}
                                </div>
                            </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Remarks (En)"  vid="remarks" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="remarks"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_config.remarks_en')}}
                                    </template>
                                    <b-form-textarea
                                        rows="2"
                                        id="remarks"
                                        v-model="roomTypeData.remarks"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <ValidationProvider name="Remarks (Bn)"  vid="remarks_bn" rules="">
                                <b-form-group
                                    class="row"
                                    label-cols-sm="4"
                                    label-for="remarks_bn"
                                    slot-scope="{ valid, errors }"
                                >
                                    <template v-slot:label>
                                    {{$t('elearning_config.remarks_bn')}}
                                    </template>
                                    <b-form-textarea
                                        rows="2"
                                        id="remarks_bn"
                                        v-model="roomTypeData.remarks_bn"
                                        :state="errors[0] ? false : (valid ? true : null)"
                                    ></b-form-textarea>
                                    <div class="invalid-feedback">
                                        {{ errors[0] }}
                                    </div>
                                </b-form-group>
                            </ValidationProvider>
                            <div class="row">
                            <div class="col-sm-3"></div>
                            <div class="col text-right">
                                <b-button type="submit" variant="primary" class="mr-2">{{ saveBtnName }}</b-button>
                                &nbsp;
                                <b-button variant="danger" class="mr-1" @click="$bvModal.hide('modal-4')">{{ $t('globalTrans.cancel') }}</b-button>
                            </div>
                            </div>
                        </b-form>
                        </ValidationObserver>
                    </b-col>
                    </b-row>
                </b-overlay>
            </template>
        </iq-card>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { core } from '@/config/pluginInit'
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import RestApi, { trainingElearningServiceBaseUrl } from '@/config/api_config'
import { roomTypeStore, roomTypeUpdate } from '../../api/routes'

export default {
  props: ['id'],
  components: {
    ValidationObserver,
    ValidationProvider
  },
  created () {
    if (this.id) {
      const tmp = this.getRoomTypeData()
      this.roomTypeData = tmp
    }
  },
  mounted () {
    core.index()
  },
  data () {
    return {
      loading: false,
      saveBtnName: this.id ? this.$t('globalTrans.update') : this.$t('globalTrans.save'),
      roomTypeData: {
        id: '',
        room_type_en: '',
        room_type_bn: '',
        remarks: '',
        remarks_bn: '',
        status: 1
      }
    }
  },
  computed: {
    orgList: function () {
      return this.$store.state.commonObj.organizationProfileList.filter(item => item.status === 0)
    },
    typeList: function () {
        return [
            { value: 1, text: this.$i18n.locale === 'en' ? 'AC' : 'এসি' },
            { value: 2, text: this.$i18n.locale === 'en' ? 'Non AC' : 'নন এসি' }
        ]
    }
  },
  watch: {
  },
  methods: {
    getRoomTypeData () {
      const tmpData = this.$store.state.list.find(item => item.id === this.id)
      return JSON.parse(JSON.stringify(tmpData))
    },
    async createData () {
        this.loading = true
        this.$store.dispatch('mutateCommonProperties', { loading: true })
        let result = null
        const loadinState = { loading: false, listReload: false }
        if (this.roomTypeData.id) {
            result = await RestApi.putData(trainingElearningServiceBaseUrl, `${roomTypeUpdate}/${this.id}`, this.roomTypeData)
        } else {
            result = await RestApi.postData(trainingElearningServiceBaseUrl, roomTypeStore, this.roomTypeData)
        }

        loadinState.listReload = true

        this.$store.dispatch('mutateCommonProperties', loadinState)

        if (result.success) {
            this.$store.dispatch('TrainingElearning/mutateTrainingElearningCommonProperties', { hasDropdownLoaded: false })
            this.$toast.success({
            title: 'Success',
            message: this.id ? this.$t('globalTrans.update_msg') : this.$t('globalTrans.save_msg'),
            color: '#D6E09B'
            })

            this.$bvModal.hide('modal-4')
        } else {
            this.$refs.form.setErrors(result.errors)
        }
        this.loading = false
    }
  }
}
</script>
